<template>
    <transition name="fade" mode="out-in" appear>
        <div class="bg-body w-screen max-w-mobile md:max-w-tablet lg:max-w-pro xl:max-w-full h-full min-h-screen mx-auto no-mobile-shadow flex flex-col justify-between py-24 items-center relative">
            <div :class="{'opacity-0': show}" style="transition-duration: 1s" class="flex flex-col z-40 transition-all ease-in-out">
                <span class="text-xl text-white">Welcome to</span>
                <img class="w-40 mt-4" src="/img/logos/logo_edem.svg" />
            </div>
            <div class="flex flex-col w-full z-40">
                <img :class="{'opacity-0': show}" src="/img/logos/marina_de_empresas.png" alt="Logo" style="transition-duration: 1s" class="h-auto w-20 mx-auto transition-all ease-in-out"/>
                <!-- <transition name="slide-fade" mode="out-in" appear> -->
                    <button :class="{'opacity-0': show}" @click="enter" class="w-56 mx-auto text-white transition-all duration-500 ease-in-out text-lg bg-clients p-3 mx-auto rounded-full mt-16">Entrar</button>
                <!-- </transition> -->
            </div>
                <img src="/img/splash-left-top.svg" :class="{'transform -translate-x-full': show}" style="transition-duration: 1.5s" class="absolute transition-all ease-in-out left-0 top-0 z-30" alt="">
                <img src="/img/splash-right-bottom.svg" style="transition-duration: 1.5s" :class="{'transform translate-x-full': show}" class="absolute transition-all ease-in-out right-0 bottom-0 z-10" alt="">
                <img src="/img/splash-right-top.svg" :class="{'transform translate-x-full': show}" style="transition-duration: 1.5s" class="absolute transition-all ease-in-out right-0 top-0 z-20" alt="">
                <img src="/img/splash-left-bottom.svg" :class="{'transform -translate-x-full': show}" style="transition-duration: 1.5s" class="absolute transition-all ease-in-out left-0 bottom-0 z-10" alt="">
        </div>
    </transition>
</template>

<script>

    //import {actions} from "@/store";

    export default {
        name: 'Splash',
        data(){
            return {
                show: false
            }
        },
        methods: {
            enter() {
                this.show = !this.show
                setTimeout(() => {
                    this.$router.push({ name: 'ExecutiveSummary'});
                },1500);
                
            }
        }
    }
</script>